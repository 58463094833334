
<template>
  <div class="corporation">
    <div  style="margin-bottom:20px;">
      <el-button type="primary" plain @click="addCorportationbtn" size="mini"><i class="el-icon-plus"></i> 新增单位</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%;" v-loading="loading" size="mini" border>
      <el-table-column prop="id" label="ID" width="100"></el-table-column>
      <el-table-column prop="name" label="单位名称"></el-table-column>
      <el-table-column prop="code" label="统一社会信用代码" width="200"></el-table-column>
      <el-table-column prop="scale" label="企业规模" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.scale == 0">未知</span>
          <span v-if="scope.row.scale == 1">0~30人</span>
          <span v-if="scope.row.scale == 2">30~100人</span>
          <span v-if="scope.row.scale == 3">100~500人</span>
          <span v-if="scope.row.scale == 4">500~1000人</span>
          <span v-if="scope.row.scale == 5">1000~3000人</span>
          <span v-if="scope.row.scale == 6">3000人以上</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="录入时间" width="160"></el-table-column>
      <el-table-column prop="mark" label="备注"></el-table-column>
      <el-table-column prop="status" label="状态" width="80">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.status == 1" size="mini">正常</el-tag>
          <el-tag type="danger" v-if="scope.row.status == 2"  size="mini">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="right" width="200">
        <template #default="scope">
          <el-button size="mini" @click="handleEdit(scope.$index)">编辑</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-popconfirm title="确定删除该单位吗？" @confirm="handleDelete(scope.$index, scope.row.id)">
            <el-button size="mini" type="danger" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next, total" :page-size="15" @current-change="handle_current" :current-page.sync="page" :total="count"></el-pagination>
    <el-dialog title="新增单位" :visible.sync="dialogFormVisible" width="30%">
      <el-form :model="form" label-position="right" label-width="150px">
        <el-form-item label="单位名称">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码">
          <el-input v-model="form.code" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="人员规模">
          <el-select v-model="form.scale" placeholder="请选择企业人员规模">
            <el-option label="未知" value=0></el-option>
            <el-option label="0~30人" value=1></el-option>
            <el-option label="30~100人" value=2></el-option>
            <el-option label="100~500人" value=3></el-option>
            <el-option label="500~1000人" value=4></el-option>
            <el-option label="1000~3000人" value=5></el-option>
            <el-option label="3000人以上" value=6></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
            <el-input type="textarea" v-model="form.mark" :rows="3" maxlength="50" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="2">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCorportation">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Corporation',
  data () {
    return {
      loading: false,
      tableData: [],
      count: 0,
      page: 1,
      dialogFormVisible: false,
      form: {
        name: '',
        scale: '',
        code: '',
        mark: '',
        status: 1
      }
    }
  },
  methods: {
    handleEdit (index) {
      this.form = ''
      this.form = this.tableData[index]
      console.log(this.form)
      this.dialogFormVisible = true
    },
    handleDelete (index, id) {
      var that = this
      this.axios.delete('/admin/corporation/' + id)
        .then(function (response) {
          if (response.data.code === 0) {
            that.$message({
              message: response.data.msg,
              type: 'success'
            })
            that.tableData.splice(index, 1)
          } else {
            that.$message.error('操作失败！')
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    handle_current (e) {
      this.getdata(e)
    },
    addCorportationbtn () {
      this.form = { name: '', scale: '', code: '', mark: '', status: 1 }
      this.dialogFormVisible = true
    },
    addCorportation () {
      var that = this
      this.axios.post('/admin/corporation', this.form)
        .then(function (response) {
          if (response.data.code === 0) {
            that.$message({
              message: response.data.msg,
              type: 'success'
            })
            that.dialogFormVisible = false
          } else {
            that.$message.error('操作失败！')
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getdata (page) {
      var that = this
      this.loading = true
      this.axios.get('/admin/corporation?page=' + page)
        .then(function (response) {
          if (response.data.code === 0) {
            that.tableData = response.data.list
            that.count = response.data.count
          }
          that.loading = false
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  created: function () {
    this.getdata(1)
  }
}
</script>

<style scoped>
.el-pagination{
  margin-top: 30px;
  text-align: center;
}
</style>
